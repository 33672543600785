@import url(https://rsms.me/inter/inter.css);
html {
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

body {
  margin: 0;
  background: #EBF3FA;
  background: linear-gradient(180deg, #EBF3FA 9.85%, #DDE7F3 53.92%, #E6F0F9 100%);
  background-attachment: fixed;
}

* {
  line-height: 1.2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

